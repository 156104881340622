@font-face {
  font-family: "Inter";
  src: local("Inter"), url(../fonts/Inter-Regular.ttf) format("woff");
  font-display: swap;
}

* {
  font-family: "Inter";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a2032;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #353a4b;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e4353;
}

.bg-content-default {
  background: #232735;
}

.card-default {
  display: flex;
  background: #2c3243;
  width: 100%;
  /* justify-content: space-between; */
  align-items: center;
  height: 82px;
  padding: 16px 24px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 8px;

  border: none !important;

  .ant-card-cover {
    padding: 16px;
    min-width: 64px;
  }

  .ant-card-actions {
    background: #2c3243;
    border: none !important;
    display: none;
    min-width: 105px;

    justify-content: flex-end !important;
    text-align: center;
    align-items: center;
  }

  .ant-card-body {
    min-width: calc(100% - 64px);
    display: flex;
    align-items: center;
  }

  .ant-card-meta {
    min-width: calc(100% - 80px);
  }

  .div-additional-info-card {
    min-width: 80px;
    display: flex;
    justify-content: end;
  }
}

.card-default:hover {
  background: #3e4353;

  .ant-card-actions {
    background: #3e4353;
    display: flex;
    min-width: 105px;
  }

  .ant-card-body {
    min-width: calc(100% - 64px - 105px);
  }
}

.card-default-body {
  /* width: calc(100vw - 268px - 80px - 105px - 30px); */

  .ant-card-meta-title {
    color: white;
  }

  .ant-card-meta-description {
    color: #959595;
  }
}

:where(.css-dev-only-do-not-override-id8a9j).ant-card .ant-card-actions>li:not(:last-child) {
  border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-id8a9j).ant-card .ant-card-actions>li {
  width: fit-content !important;
}

.footer-management {
  background: #232735;
  border-top: 1px solid #353b4f;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 13px 16px 24px;
}

.breadcrumb-cus {
  color: white !important;

  .ant-breadcrumb-link {
    color: white !important;
  }

  .ant-breadcrumb-separator {
    color: white !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
}

.btn-action-icon {
  background: #2e3550 !important;
  border: none !important;
  border-radius: 2px;
  padding: 7px !important;
}

.btn-action-icon:hover {
  background: #3e4353 !important;
}

.text-des {
  color: #969BAA;
  font-size: 14px;
}

.tabs-cus {
  .ant-tabs-nav::before {
    border-bottom: 1px solid #2c2c2c !important;
  }

  .ant-tabs-tab {
    color: #959595 !important;
    font-size: 16px !important;
  }

  .ant-tabs-tab-active,
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }

  .ant-tabs-nav {
    margin-right: 15% !important;
    margin-left: 15% !important;
  }

  .ant-tabs-nav-wrap {
    display: inline !important;
  }

  .ant-tabs-nav-list {
    justify-content: space-around;
  }
}

.card-default-item-image {
  display: flex;
  background: #2c3243;
  width: 100%;
  /* justify-content: space-between; */
  align-items: center;
  height: 100px;
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 8px;

  border: none !important;

  .ant-card-cover {
    background: black;
    border-radius: 3px;
  }
}

.card-default-item-image:hover {
  background: #3e4353;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: #1a2032 !important;
  border-color: #4f5569 !important;
}

.ant-badge .ant-badge-count {
  box-shadow: none !important;
}

.text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  padding-right: 15px;
}

.ant-empty-description {
  color: white !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  background-color: #2c3243;
  color: white;
}

/* loading */
/* Absolute Center Spinner */
.loading {
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #090b13;

  opacity: 0.8;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: none !important;
  font-size: 10px;
  width: 1em;
  height: 1em;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* margin-top: -0.5em; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* end loading */

.ant-card .ant-card-actions>li {
  width: fit-content !important;
}

.btn-action-nav {
  background: #2c3243;
  height: 28px;
  border-radius: 4px;
  padding: 6px 12px;
  border: none !important;
  color: white;
}

.ant-card-actions {
  gap: 10px;
}

.ant-card .ant-card-actions>li:not(:last-child) {
  border-inline-end: none;
}

.div-form-small {
  background: #232735;
  height: calc(-191px + 100vh);
  position: relative;
  padding: 45px 25% 45px 25%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-form-default {
  background: #232735;
  height: 100%;
  position: relative;
  padding: 0 10%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-page-default {
  background: #232735;
  height: 100%;
  position: relative;
  padding: 30px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-collapse {
  .ant-collapse-borderless {
    background: none !important;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0px;
    border-bottom: none !important;
  }

  .ant-collapse-borderless>.ant-collapse-item {
    padding-bottom: 12px !important;
    margin-bottom: 12px !important;
  }
}

.div-one-center {
  flex-direction: column;
  text-align: center;
  /* height: 100%; */
  justify-content: space-around;
  display: flex;
  align-items: center;
}

.div-center-col {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.disabled\:\!text-common-ink500:disabled {
  color: white !important;
}

.div-collapse-cus {
  .ant-collapse-borderless {
    background: #232735 !important;
  }

  .ant-collapse .ant-collapse-item:last-child>.ant-collapse-content {
    background: #2c3243 !important;
    border-radius: 4px !important;
    /* margin-top: 12px; */
    margin-bottom: 12px;
  }
}

.radio-cus-form {
  .ant-radio-wrapper span.ant-radio+* {
    color: white !important;
  }
}

.bg-div-radio {
  background: #2c3243 !important;
  padding: 16px;
  border-radius: 4px;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-color: #353b4f;
}

.card-archive-order .ant-collapse>.ant-collapse-item-active {
  background: #2C3243 !important;
  border-radius: 4px !important;

  /* border-bottom: 1px solid #353b4f !important; */
}

.card-archive-order .ant-collapse .ant-collapse-content-active {
  padding: 16px !important;
}

.card-archive-order .ant-collapse-content-box {
  padding: 0px !important;
}

.div-collection-step2 {
  /* height: 80px; */
  display: flex;
  flex-direction: column;
}

.div-collapse-cus .ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
  padding-left: 0px !important;
  font-size: 16px;
  font-weight: 600;
  /* padding-bottom: 0px !important; */
}

.div-cus-form .ant-form-item {
  margin-bottom: 0px !important;
}

.div-collapse-in-collapse {

  .ant-collapse-borderless>.ant-collapse-item:last-child,
  .ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
    /* padding: 0 !important */
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border-bottom: none !important;
  }
}

.card-archive-order {
  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    /* border-bottom: 1px solid #353b4f; */
  }
}

.div-form-step-4 {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.div-collapse-pol-point-cus {
  .ant-collapse>.ant-collapse-item-active {
    /* gap: 12px; */
    display: flex;
    flex-direction: column;
    background: #232735;
  }

  .ant-collapse .ant-collapse-item:last-child>.ant-collapse-content {
    padding: 16px !important;
    background: #2c3243 !important;
    border-radius: 4px !important;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background: #232735 !important;
    font-size: 16px;
    font-weight: 600;
    padding-left: 0px;
  }

  .ant-collapse-content-box {
    padding: 0px 0px 16px 0px !important;
  }
}

.form-cus {
  .ant-form-item .ant-form-item-label>label {
    color: white !important;
  }
}

.title-menu {
  color: white;
  font-weight: 600;
  margin-left: 16px;
}

.div-collapse-cus-info {

  .ant-collapse .ant-collapse-item:last-child>.ant-collapse-content,
  .ant-collapse .ant-collapse-content-active {
    padding: 16px !important;
    background: #2c3243 !important;
    padding-bottom: 8px !important;
  }

  .ant-collapse>.ant-collapse-item-active {
    gap: 12px;
    display: flex;
    flex-direction: column;
    background: #232735;
  }
}

.ant-form-item-required,
.ant-form-item-label,
.ant-form-item .ant-form-item-label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label>label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label>label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xl-24.ant-form-item-label>label {
  color: white !important;
}

.ant-select .ant-select-selection-item {
  color: white !important;
}

.type-image {
  /* box-shadow: 0px 0.5px 1px 0px #FFFFFF1A inset; */
  background: #ffffff0f !important;
  border-radius: 3px !important;
  padding: 8px 12px !important;
  height: 36px !important;
  color: white !important;
  font-size: 14px !important;
  cursor: pointer !important;
  width: 100% !important;
}

.active {
  background: #004e72 !important;
}

.div-item {
  height: 310px;
  object-fit: contain !important;
  background-color: black !important;
  background-repeat: no-repeat !important;
  border-radius: 4px;
  min-height: 310px;
  position: relative;
  background-position: center !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;

  .ant-checkbox .ant-checkbox-inner {
    border: 1px solid #4f5569 !important;
    background: #000000cc !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #60cdff !important;
    border: 1px solid #60cdff !important;
  }
}

.div-des-item:hover {
  height: 201px !important;

  .div-des-item-more {
    display: block;
  }

  .sub-des {
    display: none;
  }
}

.div-list-image {
  width: 349px;
  padding: 12px;
  border-radius: 3px;
  background: #232735;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
}

.div-des-item {
  position: absolute;
  color: white;
  background: #00000080;
  height: 140px;
  width: 100%;
  padding: 16px;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.text-title-item {
  /* font-family: Inter; */
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  word-wrap: break-word;
}

.text-des-item {
  font-size: 14px;
  font-weight: 400;
}

.btn-action-item {
  background: #2e323e;
  height: 24px;
  min-width: 28px;
  padding: 4px 6px;
  border-radius: 2px;
  cursor: pointer;
  width: max-content !important;
}

.btn-action-item:hover,
.shadow-hover:hover {
  box-shadow: 0px 0.5px 1px 0px #ffffff1a inset;
}

.btn-disabled {
  cursor: not-allowed !important;
}

.div-des-item-more {
  display: none;
}

.div-provider-item {
  border-radius: 4px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  min-height: 60px;

  min-height: 60px;
  min-width: 60px;
  max-width: 60px;
  object-fit: contain;
  background-size: contain !important;
}

.div-list-provider {
  width: 68px !important;
  /* overflow: auto; */
}

.badge-provider {
  /* position: absolute;
  top: -3px;
  right: -12px;
  width: 33px;
  height: 20px; */
}

.parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.child {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
}

.img-logo-provider {
  border-radius: 6px;
  /* h-[50px] w-[50px] min-w-[50px] */
  height: 50px;
  width: 50px;
  min-width: 50px;
}

.img-logo-provider:hover {
  border: 2px solid #004e72;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #60cdff !important;
  border: 1px solid #60cdff !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  border: 1px solid black !important;
  border-top: 0 !important;
  border-inline-start: 0 !important;
}

.div-total {
  height: 22px;
  width: 100%;
  background: #000000b2;
  color: white;
  font-size: 12px;
  border-radius: 0 0 4px 4px;
}

.active-provider-item {
  border: 2px solid #60cdff;
  padding: 4px;
  height: 68px;
  width: 68px;
  border-radius: 8px;
}

.ant-dropdown-menu-item-divider {
  background: #353b4f !important;
}

.horizontal-scroll-list {
  width: 100%;

  overflow-x: auto;
  white-space: nowrap;

  padding-bottom: 12px;
}

.polygon-item {
  display: inline-block;
}

.btn-action-active {
  background: #004e72 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  width: 12px;
  height: 12px;
  /* background-color: #1677ff; */
  /* background-color: #08c385; */

  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: "";
  left: 8px;
  border: none !important;
}

/* input[type="file"] {
  display: none !important;
} */

.ant-tabs .ant-tabs-tab-btn {
  color: white
}

.custom-file-upload {
  display: inline-block;
  padding: 8px 24px;
  cursor: pointer;
  background: #2e323e !important;
  border-radius: 3px;
  /* border: 1px solid rgba(255, 255, 255, 0.03); */
}

.div-btn-save-upload {
  background: #63c0eb !important;
  cursor: pointer;
}

.div-btn-upload {
  background: rgba(56, 56, 56, 1);
  color: white !important;
  margin: 10px 0;
  height: 32px;
  border-radius: 4px;
}

.btn-cancel {
  background: #2e323e;
  color: white !important;
  height: 28px !important;
  min-width: 70px;
}

.btn-header {
  min-width: 70px;
}

.btn-cancel:hover {
  box-shadow: 0px 0.5px 1px 0px #ffffff1a inset;
  background: #2e323e !important;
  color: white !important;
}

.btn-save {
  background: #60CDFF;
  color: black !important;
  height: 28px !important;
  min-width: 70px;
}

.btn-save:hover {
  box-shadow: 0px 0.5px 1px 0px white inset;
  background: #60CDFF !important;
  color: black !important;
}

.div-spin {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 99;
  background: #090b13;

  opacity: 0.8;
}

.div-show {
  .ant-spin-nested-loading>div>.ant-spin {
    max-height: 100% !important;
  }

  .ant-spin-nested-loading,
  .ant-spin-nested-loading .ant-spin-container {
    /* height: 100% !important; */
  }
}

.ant-checkbox+span {
  color: white !important;
}

.ant-radio-wrapper span.ant-radio+* {
  color: white !important;
}

.ant-badge .ant-badge-multiple-words {
  padding: 0 !important;
}

.ant-badge {
  margin-top: -16px !important;
}

.magnifier-lens {
  position: absolute;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: crosshair;
  box-shadow: 0 0 5px #000;
  pointer-events: none;
  visibility: hidden;
  background-repeat: no-repeat;
}

.div-type-image-search {
  height: 85px;
  width: 85px;
  border-radius: 4px;
  box-shadow: 0px 1px 1.5px 0px rgba(255, 255, 255, 0.1) inset;
  background: rgba(44, 50, 67, 1);
  cursor: pointer;
}

.div-type:hover {
  border: 1px solid #004e72 !important;
}

.spin-order {

  .ant-spin-nested-loading .ant-spin-blur,
  .ant-spin-nested-loading {
    min-height: calc(100vh - 56px - 56px - 60px) !important;
  }

  .ant-spin-nested-loading .ant-spin-blur {
    min-height: calc(100vh - 56px - 56px - 60px) !important;
  }
}

.div-dropdown,
.ant-select-selector {
  /* width: 100%;
  background: #2e3240 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9b9da3 !important;
  border-radius: 4px !important; */
  width: 100%;
  border: 0px solid transparent !important;
  border-radius: 4px !important;
  background-color: #3C4152 !important;
  min-height: 32px;
}

.ant-select-arrow,
.ant-steps .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role="button"] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role="button"] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role="button"] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role="button"] .ant-steps-item-icon .ant-steps-icon {
  color: white !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: unset !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  /* border-color: #585f77 !important; */
}

.ant-input-affix-wrapper .ant-input-suffix {
  /* width: 80px !important;
  min-width: 80px !important; */
}

.custom-input-price {
  .ant-input-affix-wrapper .ant-input-suffix {
    width: 80px !important;
    min-width: 80px !important;
  }
}

.custom-select-unit {
  .ant-select-selector {
    background: #3e4353 !important;
  }
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
  background: rgb(84, 113, 137) !important;
}

.ant-slider .ant-slider-rail {
  background: rgb(174, 182, 189) !important;
}


.card-archive-order-cus {
  .ant-collapse .ant-collapse-content-active {
    padding: 0 16px 16px 16px !important;
  }

}

/* .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding-top: 0px !important
} */



.map-tasking {

  .ant-spin-nested-loading .ant-spin-blur,
  .ant-spin-nested-loading,
  .ant-spin-nested-loading .ant-spin-container {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
  }
}

.ant-input-clear-icon {
  color: white !important;
}

.list-app {
  background: #1A2032;
  top: 66px;
  left: 74px;
  z-index: 9999;
  padding: 20px;
  border-radius: 16px;
  /* border: 5px solid #2E3550; */
  color: white;
  max-height: 310px;
  width: 258px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px #00000024;

}

.div-layout {
  background: url(./../assets/background.avif);
  background-position: center;
  background-size: cover;
}

.content {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(25px) saturate(180%);
  backdrop-filter: blur(25px) saturate(180%);
  height: 100%;
  width: 100%;
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal !important;
}